import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Sortable from "stimulus-sortable"
import { Dropdown, Modal, Alert, Slideover } from "tailwindcss-stimulus-components"
import Rails from '@rails/ujs'

Rails.start();
const application = Application.start()
const context = require.context("controllers", true, /_controller\.(js|ts)$/)

application.load(definitionsFromContext(context))
application.register("sortable", Sortable)
application.register('dropdown', Dropdown)
application.register('slideover', Slideover)
application.register('alert', Alert)
