import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'inputAppend', 'inputDisplay', 'focus']
  static values = { focused: Boolean }

  connect () {
    this._initializeInputs()
    this._focusOnField()
  }

  editInput () {
    this.toggleTargets(true)
    this.inputTarget.focus()
  }

  completeEditing () {
    if (!this.inputTarget.value) { return }

    this.toggleTargets(false)
  }

  toggleTargets (showInput) {
    this.inputTarget.classList.toggle('hidden', !showInput)
    this.inputDisplayTarget.classList.toggle('hidden', showInput)
    this.inputAppendTargets.forEach(function (appendTarget) {
      appendTarget.classList.toggle('hidden', !showInput)
    })
  }

  _initializeInputs () {
    if (!this.hasInputTarget) { return }

    if(!this.inputTarget.value){
      this.toggleTargets(true)
    }else {
      this.toggleTargets(false)
    }

    if (this.focusedValue) this.inputTarget.focus()
  }

  _focusOnField () {
    if (!this.hasFocusTarget) { return }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let urlFocusField = params.focus
    if(params == undefined || params.focus == undefined) { return }

    let focusField = this.focusTarget.getAttribute('data-focus-field')
    if (urlFocusField == focusField) { this.focusTarget.focus() }
  }
}
