import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
    this.backgroundId = this.data.get('backgroundId') || 'modal-background'
    this.backgroundHtml = this.data.get('backgroundHtml') || this._backgroundHTML()
    this.allowBackgroundClose = (this.data.get('allowBackgroundClose') || 'true') === 'true'
    this.preventDefaultActionOpening = (this.data.get('preventDefaultActionOpening') || 'true') === 'true'
    this.preventDefaultActionClosing = (this.data.get('preventDefaultActionClosing') || 'true') === 'true'
  }

  open(e) {
    if (this.preventDefaultActionOpening) e.preventDefault()
    if (e.target.blur) e.target.blur()

    this.containerTarget.classList.remove(this.toggleClass)

    if (!this.data.get('disable-backdrop')) {
      document.body.insertAdjacentHTML('beforeend', this.backgroundHtml)
      this.background = document.querySelector(`#${this.backgroundId}`)
    }
  }

  close(e) {
    if (e && this.preventDefaultActionClosing) e.preventDefault()
    this.containerTarget.classList.add(this.toggleClass)
    if (this.background) { this.background.remove() }
  }

  closeBackground(e) {
    if (this.allowBackgroundClose && e.target === this.containerTarget) this.close(e)
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) this.close(e)
  }

  _backgroundHTML() {
    return `<div id="${this.backgroundId}"
                 class="fixed top-0 left-0 w-full h-full"
                 style="background-color: rgba(0, 0, 0, 0.8); z-index: 9998;"></div>`
  }
}
