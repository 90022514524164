import { Controller } from "stimulus"
import { debounce } from "debounce"

/*
  This class is intended to be a base class for all forms we have in the application. It shouldn't contain any
  business specific logic. If you need to add a method for a private business case, please inherit from it and use
  the child class.
 */
export default class extends Controller {
  static targets = ['form', 'submit']

  initialize() {
    this.submit = debounce(this.submit.bind(this), 500)
  }

  connect() {
    if (!this.formTarget.requestSubmit) {
      // This is a compatibility fix for Safary. It doesn't support `form.requestSubmit()` yet.
      this.submitter = document.createElement('input')
      this.submitter.type = 'submit'
      this.submitter.hidden = true
      this.formTarget.appendChild(this.submitter)
    }
  }

  disconnect() {
    this.enableSubmits()
    this.element.toggleAttribute("data-submitting", false)
  }

  submit(event) {
    this._submitCurrentForm(event)
  }

  disableSubmits() {
    this.submitTargets.forEach(submitTarget => submitTarget.disabled = true)
  }

  enableSubmits() {
    this.submitTargets.forEach(submitTarget => submitTarget.disabled = false)
  }

  submitStart(event) {
    const form = this.element
    if (form) {
      form.toggleAttribute("data-submitting", true)
      this.disableSubmits()
    }
  }

  submitEnd(event) {
    const form = this.element
    if (form) {
      form.toggleAttribute("data-submitting", false)
      this.enableSubmits()
    }

    this._response(event)
  }

  handleSuccess(response) {
    // Extend the Form controller and add custom implementation to this function
  }

  handleError(response) {
    // Extend the Form controller and add custom implementation to this function
  }

  _response(event) {
    if (event.detail.success) {
      this.handleSuccess(event.detail)
    } else {
      this.handleError(event.detail)
    }
  }

  _submitCurrentForm(event) {
    let form = event.target.closest('form')
    if (form == this.formTarget) {
      this.submitter ? this.submitter.click() : this.formTarget.requestSubmit()
    }
  }
}
