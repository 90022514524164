import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container", "collapse", "expand", "icon" ]
  
  toggle() {
    this.containerTarget.classList.toggle('collapsed-list');
    this.collapseTarget.classList.toggle('hidden');
    this.expandTarget.classList.toggle('hidden');
    this.iconTarget.classList.toggle('rotate-180');
  }

}
