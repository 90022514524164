import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source", "tooltip"]

  copy(event){
    event.preventDefault()
    this.sourceTarget.select()
    document.execCommand("copy")
    this.tooltipTarget.innerHTML = "Copied!"
  }

  resetToolTip(){
    this.tooltipTarget.innerHTML = "Copy to Clipboard"
  }
}
