import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message", "sendButton"];

  messageValidation() {
    if (this.messageTarget.value) {
      this.sendButtonTarget.removeAttribute("disabled");
    } else {
      this.sendButtonTarget.setAttribute("disabled", "true");
    }
  }
}
