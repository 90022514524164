import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["section"]

  filter() {
    if(!this.hasSectionTarget) { return }

    this.sectionTargets.forEach(function (sectionTarget){
      let hasItems = !!sectionTarget.querySelector('.schedule-item:not(.hidden)')
      sectionTarget.classList.toggle('hidden', !hasItems)
    })
  }
}
