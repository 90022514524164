import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["option"]
  static values = {scheduleId: Number}

  selectExportOption() {
    event.target.dataset.exportOptionValue = !(event.target.dataset.exportOptionValue === "true")
    const switchHandle = event.target.children[2]
    const switchPanel = event.target.children[3]
    const switchLabel = event.target.labels[0]

    switchHandle.classList.toggle('bg-green-500')
    switchHandle.classList.toggle('bg-gray-200')
    switchPanel.classList.toggle('translate-x-5')
    switchPanel.classList.toggle('translate-x-0')
    switchLabel.classList.toggle('text-black')
  }

  submit() {
    const params = Array.from(event.target.closest("form").querySelectorAll('[data-export-option-name]')).reduce((params, element) => {
      if (element.dataset.exportOptionValue === 'true') {
        params.append(element.dataset.exportOptionName, element.dataset.exportOptionValue)
      }
      return params
    }, new URLSearchParams())
    params.append('notes', event.target.closest('form').querySelector('textarea').value)
    window.open(`/schedules2/schedules/${this.scheduleIdValue}.pdf?${params.toString()}`)
  }
}
