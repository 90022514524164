import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'display']

  connect() {
    this.updateDisplay()
  }

  updateDisplay() {
    if(this.inputTarget.value != null) {
      this.displayTarget.innerHTML= parseFloat(this.inputTarget.value)
        .toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
    }
  }
}
