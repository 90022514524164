import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  initialize() {
    if (this.containerTarget.dataset.autoClose === "true") {
      this.autoClose();
    }
    this.containerTarget.addEventListener("animationend", () => {
      const flashClosed =
        this.containerTarget.classList.contains("flash-close");
      if (flashClosed) {
        this.containerTarget.style.display = "none";
      }
    });
  }

  connect() {
    this.containerTarget.style.display = "flex";
  }

  autoClose() {
    this.autoCloseEvent = setTimeout(() => {
      this.close();
    }, 3000);
  }

  close() {
    clearTimeout(this.autoCloseEvent);
    this.containerTarget.classList.remove("flash-open");
    this.containerTarget.classList.add("flash-close");
  }
}
