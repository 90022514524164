import { Controller } from 'stimulus'
import algoliasearch from 'algoliasearch/lite'
import instantsearch from 'instantsearch.js'
import 'instantsearch.css/themes/reset.css';
import { configure, hits, pagination, searchBox } from 'instantsearch.js/es/widgets'
import { connectHits} from 'instantsearch.js/es/connectors'

export default class extends Controller {
  static targets = ['hits', 'hitsTable', 'search']
  static values = { appId: String, searchKey: String, indexName: String }

  connect() {
    const searchClient = algoliasearch(
      this.appIdValue,
      this.searchKeyValue
    );

    this.search = instantsearch({
      indexName: this.indexNameValue,
      searchClient,
      searchFunction(helper) {
        const hitsContainer = document.querySelector('#hits');
        const cardsContainer = document.querySelector('#address_book');

        if (helper.state.query === '') {
          hitsContainer.classList.add('hidden');
          cardsContainer.classList.remove('hidden');
        } else {
          cardsContainer.classList.add('hidden');
          hitsContainer.classList.remove('hidden');
        }

        helper.search();
      }
    });

    this.configureSearch();
  }

  addSearchBoxWidget() {
    this.search.addWidgets([
      searchBox({
        container: this.searchTarget,
        placeholder: "Search contacts...",
      })
    ]);
  }

  addHitsWidget() {
    this.search.addWidgets([
      this.hitsTable()
    ]);
  }

  get hitsTable() {
    return connectHits(
      function renderHits(renderOptions, isFirstRendering) {
        const { hits, results, widgetParams } = renderOptions;
        const container  = $('#hitsTable');

        if (results?.nbHits) {
          $(container).html(hits.map(hit => `
              <tr class="hit-rows">
                <td class="flex items-center">
                  <div>
                    ${ hit.company_contact_logo ?
                      `<div class="w-9 h-9">
                        <img src=${ hit.company_contact_logo }/>
                      </div>` :
                      `<div class="flex items-center justify-center w-9 h-9 text-white bg-primary-text">
                          ${ hit.name.match(/\b(\w)/g).join('').toUpperCase() }
                      </div>`
                    }
                  </div>
                  <div class="px-2">
                    ${ hit._highlightResult.name.value }
                  </div>
                </td>
                <td> ${ hit._highlightResult.role.value } </td>
                <td> ${ hit._highlightResult.email.value } </td>
                <td> ${ hit._highlightResult.phone.value } </td>
              </tr>
            `
          ));
        } else {
          $(container).html(`
            <tr class="hit-rows"> <td class="text-gray-400"> No results </td> </tr>
          `)
        }
      }
    );
  }

  startSearch() {
    this.search.start();
  }

  configureSearch() {
    this.addSearchBoxWidget();
    this.addHitsWidget();
    this.startSearch();
  }
}