import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["trigger", "menu"];

  goDown() {
    this.triggerTarget.classList.add('hidden')
    this.menuTarget.classList.remove('hidden')
  }

  goUp() {
    this.menuTarget.classList.add('hidden')
    this.triggerTarget.classList.remove('hidden')
  }
}
