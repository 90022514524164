import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.hide()
    }, 3000)
  }

  hide() {
    this.element.setAttribute(
      'style',
      "transition: 0.5s; transform:translate(500px, 0);"
    )
  }
}
