import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    url: String
  }

  perform(event) {
    const sectionId = event.target.dataset.sectionId;
    const csrf_token = document.querySelector('meta[name="csrf-token"]').content;

    if (!sectionId || !csrf_token) {
      return;
    }
    
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrf_token
      },
      body: JSON.stringify({
        schedule_section_id: sectionId
      }),
    }).then((response) => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
