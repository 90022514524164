import { Controller } from "stimulus";
import { FetchRequest } from "@rails/request.js";
import { debounce } from "debounce";
import closeIcon from "./../../assets/images/close-icon-red.svg";

export default class extends Controller {
  static targets = ["container", "value", "search", "options"];

  initialize() {
    this.labels = {};
    const labelFields = this.containerTarget.children;
    for (let i = 0; i < labelFields.length; i++) {
      const [labelKey, labelProperties] = this.getLabelProperties(labelFields[i]);
      this.labels[labelKey] = labelProperties;
    }
    this.assignLabels();
  }

  search = debounce(() => this.initiateSearch(), 300);

  async initiateSearch() {
    const searchTerm = this.searchTarget.value;
    const request = new FetchRequest(
      "get",
      "/address_book/company_contacts/search_labels",
      { query: { search_term: searchTerm }, responseKind: "turbo-stream" }
    );
    request.perform();
  }

  addTag(event) {
    const labelValue = event.target.dataset.value;
    const labelKey = labelValue.toLowerCase();
    if (labelKey in this.labels && this.labels[labelKey]["remove"] !== true)
      return;

    this.labels[labelKey] = {
      persisted:
        (this.labels[labelKey] && this.labels[labelKey].persisted) || false,
      remove: false,
      value: labelValue
    };
    const label = this.buildLabel(labelValue);
    this.containerTarget.append(label);
    this.assignLabels();
  }

  removeTag(event) {
    const label = event.target.parentNode.parentNode;
    const labelKey = label.dataset.value.toLowerCase();
    if (this.labels[labelKey]["persisted"]) {
      this.labels[labelKey]["remove"] = true;
    } else {
      delete this.labels[labelKey];
    }
    label.remove();
    this.assignLabels();
  }

  buildLabel(labelValue) {
    const labelContent = document.createTextNode(labelValue);
    const label = document.createElement("div");
    label.classList.add("primary-tag");
    label.setAttribute("data-value", labelValue);
    label.appendChild(labelContent);
    label.appendChild(this.removeLabelOption());
    return label;
  }

  removeLabelOption() {
    const labelRemoveOption = document.createElement("div");
    const labelRemoveIcon = document.createElement("img");
    labelRemoveOption.classList.add("tag-remove");
    labelRemoveIcon.setAttribute("data-action", "click->tags#removeTag");
    labelRemoveIcon.src = closeIcon;
    labelRemoveOption.appendChild(labelRemoveIcon);
    return labelRemoveOption;
  }

  getLabelProperties(labelElement) {
    return [
      labelElement.dataset.value.toLowerCase(),
      {
        persisted: labelElement.dataset.persisted,
        remove: false,
        value: labelElement.dataset.value
      },
    ];
  }

  assignLabels() {
    this.valueTarget.value = JSON.stringify(this.labels);
  }
}
