import { Controller } from 'stimulus'
import { FetchRequest } from "@rails/request.js"
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static targets = ['checkoutButton']

  static values  = { url: String, sessionMode: String }

  async initialize() {
    this.stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);
  }

  async handleCheckout() {
    const request  = new FetchRequest('post', this.urlValue, { body: { session_mode: this.sessionModeValue} });
    const response = await request.perform();

    if (response.ok) {
      this.session = await response.json;
      this.redirectToCheckout();
    } else {
      const error_response =  await response.json;
      toastr.error(error_response["errors"]);
    }
  }

  async redirectToCheckout() {
    const result = await this.stripe.redirectToCheckout({
      sessionId: this.session["session"]["id"],
    });

    if (result.error) { toastr.error(result.error.message) };
  }
}
