import FormController from "./form_controller"

export default class extends FormController {
  static values = { archivedIncluded: Boolean }

  static targets = [
    "sortInput",
    "orderInput",
    "filterInputs",
    "inputSearch",
    "archivedIncluded",
    "archivedIncludedShowTitle",
    "archivedIncludedHideTitle",
    "statusInput",
    "leadTimeInput",
    "submitButton",
    "order",
    "addonSelected"
  ]

  connect() {
    super.connect()

    this.element.addEventListener('keydown', event => {
      if (event.keyCode === 13) { 
        this.syncInputs(event)
        this.submit(event) 
      }
    })
  }

  optionSelected(event) {
    this.selectedOption = event.target.dataset.value
    this.filterSelectDropdownController.select(event)
    this.showAddonElement(event)
  }

  showAddonElement(event) {
    this.filterInputsTarget.children.forEach(input => {
      if (input.getAttribute('id') === `query_${this.selectedOption}`) {
        input.classList.remove('hidden')
      } else {
        input.classList.add('hidden')
      }
    })
  }

  submit(event) {
    this.closeAllSelectDropdowns(event)
    super.submit(event)
  }

  clearSearch(event) {
    if (this.hasInputSearchTarget) {
      this.inputSearchTarget.value = ''
    }
    this.submit(event);
  }

  submitStatus(event) {
    this.statusInputTarget.value = event.target.dataset.value
    this.submit(event)
  }

  submitLeadTime(event) {
    const val = Number(event.target.dataset.value);
    this.leadTimeInputTarget.value = Number.isInteger(val) ? event.target.dataset.value : ''
    this.submit(event)
  }

  selectSort(event) {
    this.orderTargets.forEach(orderTarget => {
      orderTarget.classList.toggle('sort-active', false)
    })
    this.sortSelectDropdownController.select(event)
    this.sortInputTarget.value = event.target.dataset.value
  }

  submitSort(event) {
    event.currentTarget.parentElement.children.forEach(buttonElem => {
      buttonElem.classList.toggle('sort-active', false)
    })
    event.currentTarget.classList.toggle('sort-active', true)
    this.orderInputTarget.value = event.currentTarget.dataset.value
    this.submit(event)
  }

  clearSort(event) {
    event.currentTarget.parentElement.children.forEach(buttonElem => {
      buttonElem.classList.toggle('sort-active', false)
    })
    this.sortInputTarget.value = ''
    this.orderInputTarget.value = ''
    this.sortSelectDropdownController.hideOptions()
    this.sortSelectDropdownController.hideAddon()
    this.sortSelectDropdownController.selectedOptionTarget.innerHTML = 'None'
    this.submit(event)
  }

  closeAllSelectDropdowns(event) {
    this.allSelectDropdownControllers.map(c => c.hideOptions())
  }

  toggleArchivedItems(event) {
    this.archivedIncludedValue = !this.archivedIncludedValue
    this.archivedIncludedTarget.value = this.archivedIncludedValue
    this.archivedIncludedShowTitleTarget.classList.toggle('hidden')
    this.archivedIncludedHideTitleTarget.classList.toggle('hidden')

    this.submit(event)
  }

  clearFilters(event) {
    this.closeAllSelectDropdowns(event)
    this.clearInputs()
    this.clearSearch()
    this.restoreAddons()
    this.submit(event)
  }

  clearInputs() {
    this.filterInputsTarget.children.forEach(child => {
      let childId = child.getAttribute('id');

      if (childId.startsWith('query_')) {
        if (child.nodeName == 'INPUT') {
          child.value = '';
        } else {
          child.querySelectorAll('input').forEach(input => input.value = '')
        }
      }
    })
  }

  restoreAddons() {
    if (this.hasAddonSelectedTarget) {
      this.addonSelectedTargets.forEach(addon => {
        let optionSelected = addon.querySelector('div[data-select-dropdown-target="selectedOption"]')
        if (optionSelected) {
          optionSelected.innerHTML = 'Select'
        }
      })
    }
  }

  changeCheckboxValue(event) {
    let input = event.target
    let inputList = this.element.querySelectorAll(`input[name="${event.target.name}"]`)
    inputList.forEach(inp => {
      inp.checked = input.checked
    })
    this.submit(event)
  }

  syncInputs(event) {
    let val = event.target.value;
    let inputList = this.element.querySelectorAll(`input[name="${event.target.name}"]`)

    if (inputList.length > 1) {
      inputList.forEach(inp => inp.value = val)
    }
  }

  get sortSelectDropdownController() {
    return this.element.querySelector('#querySort').querySort
  }

  get filterSelectDropdownController() {
    return this.element.querySelector('#queryFilter').queryFilter
  }

  get allSelectDropdownControllers() {
    return this.application.controllers.filter(c => c.identifier === "select-dropdown")
  }
}
