import Dropzone from "dropzone"
import { Controller } from "stimulus"
import { DirectUpload } from "activestorage"
import { getMetaValue, findElement, removeElement, insertAfter } from "helpers"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    const dropZoneAttributes = {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFilesize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: this.addRemoveLinks,
      disablePreviews: this.previewsDisabled,
      autoQueue: false
    }

    if (this._previewTemplate) dropZoneAttributes['previewTemplate'] = this._previewTemplate

    this.dropZone = new Dropzone(this.element, dropZoneAttributes)
    this._hideFileInput()
    this._bindEvents()
    Dropzone.autoDiscover = false
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles")
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true
  }

  get previewsDisabled() {
    return this.data.get("previewsDisabled") === 'true'
  }

  get _previewTemplate() {
    const previewTemplate = findElement(this.element, '.dz-preview-template')
    return (previewTemplate == null) ? '' : previewTemplate.innerHTML
  }

  _hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  _bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && new DirectUploadController(this, file).start().then( _ =>
          this.inputTarget.dispatchEvent(new Event("change"))
        )
      }, 500)
    })

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort()
    })
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = new DirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()

    return new Promise((resolve, reject) => {
      this.directUpload.create((error, attributes) => {
        if (error) {
          removeElement(this.hiddenInput)
          this.emitDropzoneError(error)
          reject
        } else {
          this.hiddenInput.value = attributes.signed_id
          this.emitDropzoneSuccess()
          resolve(attributes.signed_id)
        }
      })
    })
  }

  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    )
  }

  uploadRequestDidProgress(event) {
    if (this.source.previewsDisabled) return

    const progress = (event.loaded / event.total) * 100
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}
