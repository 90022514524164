import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  initialize() {
    this.containerTarget.addEventListener("animationend", () => {
      const slideoutClosed =
        this.containerTarget.classList.contains("slideout-closed");
      if (slideoutClosed) {
        this.containerTarget.style.display = "none";
      }
    });
  }

  closeSlideout() {
    this.containerTarget.classList.remove("slideout-open");
    this.containerTarget.classList.add("slideout-closed");
  }

  openSlideout() {
    this.containerTarget.style.display = "block";
    this.containerTarget.classList.remove("slideout-closed");
    this.containerTarget.classList.add("slideout-open");
  }
}
