import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainPanel", 'replacePanel']

  toggle(event) {
    this.mainPanelTarget.classList.toggle('flex')
    this.mainPanelTarget.classList.toggle('hidden')
    this.replacePanelTarget.classList.toggle('flex')
    this.replacePanelTarget.classList.toggle('hidden')
  }
}
