import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['message']
  static values = {
    url: String
  }

  submit() {
    let messageTarget = this.messageTarget
    let message = messageTarget.value
    let inputData = {
      message: message
    }
    messageTarget.value = null
    if(!message || !this.hasUrlValue){
      return
    }

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrf_token
      },
      body: JSON.stringify(inputData),
    }).then((response) => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
