import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['projectLevel', 'scheduleLevel', 'scheduleSectionLevel', 'source', 'selectableProjectSource',
                    'selectableScheduleSource', 'filterableProject', 'filterableSchedule', 'filterableScheduleSection',
                    'selectableProject', 'selectableSchedule', 'selectableScheduleSection', 'projectHeader', 'scheduleHeader',
                    'sectionHeader', 'projectNameHeader', 'scheduleNameHeader', 'copySection']
  static values = { currentLevel: String }

  connect() {
    this.currentLevelValue = 'Project';
  }

  resetLists() {
    this.navToProjects();
  }

  filter() {
    let currentTargets = this['filterable'+this.currentLevelValue+'Targets'];
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase();

    currentTargets.forEach((el) => {
      let filterableKey =  el.getAttribute("data-filter-string-key").toLowerCase();
      el.classList.toggle("hidden", !filterableKey.includes(lowerCaseFilterTerm));
    })
  }

  navToProjects() {
    this.resetSearchField();
    this.currentLevelValue = 'Project';
    this.toggleHideAll(this.projectLevelTargets, false);
    this.toggleHideAll(this.selectableProjectTargets, false);
    this.toggleHideAll(this.selectableScheduleTargets, false);
    this.toggleHideAll(this.projectNameHeaderTargets, true);
    this.toggleHideAll(this.scheduleLevelTargets, true);
    this.toggleHideAll(this.scheduleSectionLevelTargets, true);
    this.toggleHideAll(this.scheduleNameHeaderTargets, false);
    if (this.hasSourceTarget) this.toggleHideTarget(this.scheduleHeaderTarget, true);
    if (this.hasProjectHeaderTarget) this.toggleHideTarget(this.projectHeaderTarget, false);
    if (this.hasSectionHeaderTarget) this.toggleHideTarget(this.sectionHeaderTarget, true);
  }

  navToSchedules() {
    this.resetSearchField();
    this.currentLevelValue = 'Schedule';
    this.toggleHideAll(this.scheduleSectionLevelTargets, true);
    this.toggleHideAll(this.scheduleLevelTargets, false);
    this.toggleHideAll(this.selectableScheduleTargets, false);
    this.toggleHideAll(this.scheduleNameHeaderTargets, false);
    this.toggleHideAll(this.projectNameHeaderTargets, false);

    this.toggleHideTarget(this.scheduleHeaderTarget, false);
    this.toggleHideTarget(this.sectionHeaderTarget, true);
  }

  selectProject(e) {
    this.resetSearchField();
    this.currentLevelValue = 'Schedule';
    this.toggleHideAll(this.projectNameHeaderTargets, false);
    this.projectHeaderTarget.classList.toggle('hidden');
    this.scheduleHeaderTarget.classList.toggle('hidden');
    this.projectLevelTargets.forEach((el) => {
      el.classList.toggle('hidden', true);
    })
    let selectedTarget = this.selectEventTarget(this.selectableProjectSourceTargets, e);
    let projectId = selectedTarget.getAttribute('data-project-id');
    this.filterProjectSchedules(projectId);
  }

  selectSchedule(e) {
    this.resetSearchField();
    this.currentLevelValue = 'ScheduleSection';
    this.toggleHideAll(this.scheduleNameHeaderTargets, true);
    this.toggleHideAll(this.projectNameHeaderTargets, true);
    this.toggleHideTarget(this.scheduleHeaderTarget, true);
    this.toggleHideTarget(this.sectionHeaderTarget, false);
    let selectedTarget = this.selectEventTarget(this.selectableScheduleSourceTargets, e);
    let scheduleId = selectedTarget.getAttribute('data-source-schedule-id');
    this.filterScheduleSection(scheduleId);

    this.toggleHideAll(this.scheduleSectionLevelTargets, false);
  }

  filterScheduleSection(scheduleId) {
    this.selectableScheduleTargets.forEach((el) => {
      let filterableScheduleId = el.getAttribute('schedule-filter-id');
      el.classList.toggle('hidden', filterableScheduleId != scheduleId);
    })
  }

  filterProjectSchedules(projectId) {
    this.selectableProjectTargets.forEach((el) => {
      let filterableProjectId = el.getAttribute('project-filter-id');
      el.classList.toggle('hidden', filterableProjectId != projectId);
    })
    this.toggleHideAll(this.scheduleLevelTargets, false);
  }

  selectEventTarget(eventTargets, event) {
    return eventTargets.find((eventTarget) => {
      return eventTarget == event.currentTarget;
    })
  }

  toggleHideAll(targetGroup, condition) {
    targetGroup.forEach((el) => {
      el.classList.toggle('hidden', condition);
    })
  }

  toggleHideTarget(target, condition) {
    target.classList.toggle('hidden', condition);
  }

  resetSearchField(){
    let currentTargets = this['filterable'+this.currentLevelValue+'Targets'];
    this.toggleHideAll(currentTargets, false);
    if (this.hasSourceTarget) this.sourceTarget.value = '';
  }
}
