import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container', 'overlay', 'panel', 'reloadableFrame']
  static classes = ['toggle', 'overlayEnter', 'overlayLeave', 'panelEnter', 'panelLeave']
  static values = {open: Boolean, leaveTimeout: Number}

  connect() {
    this.leaveTimeoutValue = this.leaveTimeoutValue || 500
    window.addEventListener('keydown', this._onKeydown.bind(this))
  }

  disconnect() {
    window.removeEventListener('keydown', this._onKeydown)
  }

  toggle() {
    this.openValue = !this.openValue
  }

  show(event) {
    event.preventDefault()
    this.openValue = true
  }

  hide() {
    this.openValue = false
  }

  openValueChanged() {
    this.openValue ? this._show() : this._hide()
  }

  _show() {
    setTimeout(
      (() => {
        this.containerTarget.classList.remove(this.toggleClass)
        setTimeout(
          () => {
            this.overlayTarget.classList.add(...this.overlayEnterClass.split(' '))
            this.panelTarget.classList.remove(...this.panelLeaveClass.split(' '))
            this.panelTarget.classList.add(...this.panelEnterClass.split(' '))
            if (this.hasReloadableFrameTarget) {
              // With the new release candidate of turbo (7.0.0-rc.4) they have introduced a new frame attribute
              // 'reloadable'. It aims to reload a frame when it switched from invisible to visible. Unfortunately it
              // doesn't work, so we needed to come up with a more hacky solution using the side effect that the turbo
              // frame gets reloaded every time its src attribute gets changed.
              let tempURL = this.reloadableFrameTarget.src
              this.reloadableFrameTarget.src = ''
              this.reloadableFrameTarget.src = tempURL
            }
          },
          50,
        )
      })
    )
  }

  _hide() {
    setTimeout(
      (() => {
        this.overlayTarget.classList.remove(...this.overlayEnterClass.split(' '))
        this.panelTarget.classList.remove(...this.panelEnterClass.split(' '))
        this.overlayTarget.classList.add(...this.overlayLeaveClass.split(' '))
        this.panelTarget.classList.add(...this.panelLeaveClass.split(' '))
        setTimeout(
          (() => {
            this.containerTarget.classList.add(...this.toggleClass.split(' '))
          }),
          this.leaveTimeoutValue,
        )
      })
    )
  }

  _onKeydown(event) {
    if (event.code === 'Escape') {
      this.hide()
    }
  }
}
