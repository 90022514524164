import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['level1', 'level2']

  toLevel1() {
    this.level1Target.classList.toggle('invisible', false)
    this.level1Target.classList.toggle('visible', true)
    this.level2Target.classList.toggle('invisible', true)
    this.level2Target.classList.toggle('visible', false)
  }

  toLevel2() {
    this.level2Target.classList.toggle('invisible', false)
    this.level2Target.classList.toggle('visible', true)
    this.level1Target.classList.toggle('invisible', true)
    this.level1Target.classList.toggle('visible', false)
  }
}
