import { Controller } from "stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  initialize () {
    this.onEnd = this.onEnd.bind(this)
    this.onStart = this.onStart.bind(this)
    this.onUnchoose = this.onUnchoose.bind(this)

    if (!this.sortable) {
      this.sortable = new Sortable(this.element, { 
        handle: '.dnd-handle',
        ghostClass: 'bg-gray-100',
        dragClass: 'items-collapsed',
        onStart: this.onStart,
        onUnchoose: this.onUnchoose,
        onEnd: this.onEnd,
      })
    }
  }

  onStart(event) {
    if (event.item.classList.contains('schedule-section')) {
      this.collapse();
    }
  }

  onEnd(event) {
    this.uncollapse();
    this.perform(event);
  }

  onUnchoose() {
    this.uncollapse();
  }

  perform({ item, newIndex }) {
    const url = item.dataset.url
    const sortKey = this.getSortKey(item)
    const form = document.createElement('form')
    form.action = url.indexOf('?') == -1 ? `${url}?${sortKey}=${newIndex}` : `${url}&${sortKey}=${newIndex}`
    form.method = 'post'
    form.style.display = 'none'
    form.dataset.remote = true

    const button = document.createElement('button')
    button.type = 'submit'

    const input_method = document.createElement('input')
    input_method.name = '_method'
    input_method.value = 'patch'

    form.appendChild(input_method)
    form.appendChild(button)
    document.body.appendChild(form)
    button.click()
    document.body.removeChild(form)
  }

  collapse() {
    this.nestedList.forEach(el => el.classList.add('items-collapsed'))
  }

  uncollapse() {
    this.nestedList.forEach(el => el.classList.remove('items-collapsed'))
  }

  getSortKey(item) {
    return item.dataset.sortKey || 'position';
  }

  get nestedList() {
    return document.getElementsByClassName('dnd-nested-list')
  }
}
