import {Controller} from "stimulus";

/*
This is a base controller responsible for submitting input fields as AJAX parameters. A good example of usage
is adding a toggle, or an input field to a dropdown menu. If you consider adding more code to it, it shouldn't be
business logic. Any business logic belongs to a new subclass of this class. For reference, please see the implementation
of the RenameScheduleController, or the TermsAndConditionsController.
 */
export default class extends Controller {
  static values = { url: String }
  static targets = ['input']

  onSuccess(data) {
    //override this for after submit actions
  }

  onError(error) {
    //override this for after submit actions
  }

  submit() {
    let requestParams = this.inputTargets.reduce((params, element) => {
      params[element.name] = element.value
      return params
    }, {})

    this.patch(this.urlValue, requestParams)
      .then(response => response.json())
      .then(data => {
        this.onSuccess(data)
      })
      .catch(error => {
        this.onError(error)
      })
  }

  patch(url, params) {
    return fetch(url, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrf_token
      },
      body: JSON.stringify(params)
    })
  }
}
