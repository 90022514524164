import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['filterable', 'keyword']

  apply(event) {
    let filterValue = event.target.value.toLowerCase()
    this.filterableTargets.forEach((target) => {
      let targetValue = target.getAttribute('data-filterable-value').toLowerCase()
      target.classList.toggle("hidden", !targetValue.includes(filterValue))
    })
  }

  clearFilter() {
    this.filterableTargets.forEach((target) => {
      target.classList.toggle("hidden", false)
    })

    if(this.hasKeywordTarget) {
      this.keywordTarget.value = ''
    }
  }
}
