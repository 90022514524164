import { Dropdown } from "tailwindcss-stimulus-components"

export default class extends Dropdown {
  static targets = [
    "menu",
    "optionsState",
    "options",
    "selectedOption", 
    "optionsAddon",
  ]

  connect() {
    super.connect();
    this.element[this.element.id] = this;
  }

  select(event, optionalCallback = null) {
    this.selectedOption = event.target.dataset.value
    this.selectedOptionTarget.innerHTML = event.target.innerHTML
    this.toggleOptions()

    if (optionalCallback) {
      return optionalCallback()
    } else {
      return this.showOptionsAddon()
    }
  }

  toggleOptions() {
    if (this.menuTarget.classList.contains('absolute')) {
      this.hideOptions()
    } else {
      this.showOptions()
    }
  }

  showOptions() {
    this.menuTarget.classList.remove('hidden')
    this.menuTarget.classList.add('absolute')
    if (this.hasOptionsStateTarget) {
      this.optionsStateTarget.classList.add('transform', 'rotate-180')
    }
  }

  hideOptions() {
    this.menuTarget.classList.remove('absolute')
    this.menuTarget.classList.add('hidden')
    if (this.hasOptionsStateTarget) {
      this.optionsStateTarget.classList.remove('transform', 'rotate-180')
    }
  }

  showOptionsAddon() {
    if (this.hasOptionsAddonTarget) {
      if (this.selectedOption === 'none') {
        this.hideAddon()
      } else {
        this.showAddon()
      }
    }
  }

  hideAddon() {
    this.optionsAddonTarget.classList.remove('flex')
    this.optionsAddonTarget.classList.add('hidden')
  }

  showAddon() {
    this.optionsAddonTarget.classList.remove('hidden')
    this.optionsAddonTarget.classList.add('flex')
  }
}
