import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['sectionTotal', 'viewTypeDefaultHeading', 'viewTypeFinancialHeading', 'sectionTotalDesigner',
  'sectionTotalClient', 'scheduleTotal', 'scheduleTotalDesigner', 'scheduleTotalClient']

  connect() {
    this.markup = this.markupEnabled
    this.financialView = false
    this.element[this.element.id] = this;
    let $this = this
    document.addEventListener("turbo:before-stream-render", function (e) {
      $this.updateAllSectionTotals($this)
      $this.updateScheduleTotal($this)
    })
  }

  showArchivedItems() {
    return this.sectionTotalTarget.getAttribute('data-show-archived')
  }

  updateAllSectionTotals($this) {
    let sectionTargets = document.querySelectorAll('.schedule-section')
    sectionTargets.forEach(function (section){
      $this.updateSectionTotal(section, $this)
    })
  }

  updateSectionTotal(sectionTarget, $this) {
    let sectionId = sectionTarget.getAttribute('data-section-id')
    let url = '/schedules2/schedule_sections/'+sectionId+'/financials'
    let inputData = {
      show_archived: $this.showArchivedItems()
    }
    let sectionFinancialData
    this.fetchData(url, 'get', inputData).then(response => {
      sectionFinancialData = response
      let sectionDesignerTotalTarget = sectionTarget.querySelector('.section-total-designer')
      let sectionClientTotalTarget = sectionTarget.querySelector('.section-total-client')

      let sectionTotalDesignerCost = $this.getCurrencyValue(sectionFinancialData.total_designer_cost)
      let sectionTotalClientPrice = $this.getCurrencyValue(sectionFinancialData.total_client_price)

      $this.setSectionTotal(sectionDesignerTotalTarget, sectionTotalDesignerCost)
      $this.setSectionTotal(sectionClientTotalTarget, sectionTotalClientPrice)
    })
  }

  updateScheduleTotal($this) {
    let scheduleId = $this.scheduleTotalTarget.getAttribute('data-schedule-id')
    let url = '/schedules2/schedules/'+scheduleId+'/financials'
    let inputData = {
      show_archived: $this.showArchivedItems()
    }
    let scheduleFinancialData
    this.fetchData(url, 'get', inputData).then(response => {
      scheduleFinancialData = response
      let scheduleDesignerTotalTarget = document.querySelector('.schedule-total-designer')
      let scheduleClientTotalTarget = document.querySelector('.schedule-total-client')

      let scheduleTotalDesignerCost = $this.getCurrencyValue(scheduleFinancialData.total_designer_cost)
      let scheduleTotalClientPrice = $this.getCurrencyValue(scheduleFinancialData.total_client_price)

      $this.setSectionTotal(scheduleDesignerTotalTarget, scheduleTotalDesignerCost)
      $this.setSectionTotal(scheduleClientTotalTarget, scheduleTotalClientPrice)
    })
  }

  toggleMarkup(event) {
    this.markup = this.markupEnabled
    let markupFlag = this.markup
    this.toggleSectionTotalSource(markupFlag)
    this.toggleScheduleTotalSource(markupFlag)
    this.financialViewCalculateControllers.forEach(function (finCalculateController){
      finCalculateController.renderMarkupSectionsView(markupFlag)
      finCalculateController.propagateTotalsChange()
    })
  }

  enableFinancialView() {
    this.financialView = true
    this.toggleFinancialSections()
    this.toggleSwitchStyle()
    this.toggleSectionTotalView()
  }

  enableDefaultView() {
    this.financialView = false
    this.toggleFinancialSections()
    this.toggleSwitchStyle()
    this.toggleSectionTotalView()
  }

  toggleFinancialSections() {
    let financialTarget = document.querySelectorAll('.financial')
    let defaultTarget = document.querySelectorAll('.default')
    let financialView = this.financialView
    financialTarget.forEach(function (financialTargetItem) {
      financialTargetItem.classList.toggle('hidden', !financialView)
    })
    defaultTarget.forEach(function (defaultTargetItem) {
      defaultTargetItem.classList.toggle('hidden', financialView)
    })
  }

  setSectionTotal(target, total){
    target.innerHTML = (total == undefined || total == 0) ? ""
      : total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }

  toggleSectionTotalView() {
    let financialView = this.financialView
    this.sectionTotalTargets.forEach(function (sectionTotalTarget) {
      sectionTotalTarget.classList.toggle('hidden', !financialView)
    })
    this.scheduleTotalTarget.classList.toggle('hidden', !financialView)
  }

  toggleSectionTotalSource(markupFlag) {
    this.sectionTotalDesignerTargets.forEach(function (designerTotalTarget) {
      designerTotalTarget.classList.toggle('hidden', markupFlag)
    })
    this.sectionTotalClientTargets.forEach(function (clientTotalTarget) {
      clientTotalTarget.classList.toggle('hidden', !markupFlag)
    })
  }

  toggleScheduleTotalSource(markupFlag) {
    this.scheduleTotalDesignerTarget.classList.toggle('hidden', markupFlag)
    this.scheduleTotalClientTarget.classList.toggle('hidden', !markupFlag)
  }

  toggleSwitchStyle() {
    let financialView = this.financialView
    let defaultSwitchButton = document.querySelector('.financial-switch-default')
    let financialSwitchButton = document.querySelector('.financial-switch-financial')
    defaultSwitchButton.classList.toggle('border-bottom-highlight', !financialView)
    financialSwitchButton.classList.toggle('border-bottom-highlight', financialView)
    this.viewTypeDefaultHeadingTarget.classList.toggle('text-gray-550', financialView)
    this.viewTypeFinancialHeadingTarget.classList.toggle('text-gray-550', !financialView)
    this.viewTypeDefaultHeadingTarget.classList.toggle('pt-0.5', !financialView)
    this.viewTypeFinancialHeadingTarget.classList.toggle('pt-0.5', financialView)
  }

  get financialViewCalculateControllers() {
    let finacialViewCalculateList = []
    document.querySelectorAll('.financialViewCalculate').forEach(function (financialViewElement){
      finacialViewCalculateList.push(financialViewElement.financialViewCalculate)
    })
    return finacialViewCalculateList
  }

  get markupEnabled() {
    return document.querySelector('#markup_enabled').value === 'true'
  }

  fetchData(url, method, inputData){
    return fetch(url + '?' + new URLSearchParams(inputData), {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrf_token
      }
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
      })
  }
  getCurrencyValue(amount) {
    return ((amount == undefined || amount === 0) ? "" :
      parseFloat(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
  }
}
