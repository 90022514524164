import Sortable from "stimulus-sortable"

export default class extends Sortable {
  connect() {
    super.connect()
  }

  end(event) {
    event.item.getElementsByClassName("position_field")[0].value = event.newIndex - 1
    const form = event.target.closest('form'); 
    if (form) {
      form.requestSubmit();
    }
  }
}
