import AjaxController from "./ajax_controller"

export default class extends AjaxController {
  static values = { state: Boolean }

  connect() {
    this.element[this.identifier] = this

    if (this.stateValue) {
      this._toggleStyles()
    }
  }

  switch(event) {
    this.stateValue = !this.stateValue
    this._toggleStyles()
  }

  submit() {
    this.inputTarget.value = this.element.toggle.stateValue
    super.submit()
  }

  _toggleStyles() {
    let switchHandle = this.element.querySelector('[role="handle"]')
    let switchPanel = this.element.querySelector('[role="panel"]')

    switchHandle.classList.toggle('bg-green-500')
    switchHandle.classList.toggle('bg-gray-200')
    switchPanel.classList.toggle('translate-x-5')
    switchPanel.classList.toggle('translate-x-0')
  }
}
