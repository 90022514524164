import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['tab', 'panel']

  switch(event) {
    event.preventDefault()

    let selectedPanelId = event.target.dataset.tabsPanelRef

    this.tabTargets.forEach((target) => {
      target.classList.toggle("active", target.dataset.tabsPanelRef == selectedPanelId)
    })

    this.panelTargets.forEach((target) => {
      target.classList.toggle("hidden", target.dataset.tabsPanelId != selectedPanelId)
    })
  }
}
