import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'display']

  connect() {
    this.updateDisplay()
  }

  updateDisplay() {
    let displayValue = this.inputTarget.value
    if(['', undefined, null].includes(displayValue)) {
      this.displayTarget.innerHTML =  "<div class='text-gray-500 whitespace-nowrap'> -   %</div>"
    }else{
      displayValue = this._is_integer(displayValue) ? parseInt(displayValue) : displayValue
      this.displayTarget.innerHTML = displayValue + "%"

    }
  }

  _is_integer (number) {
    return number % 1 === 0
  }
}
