import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['fileInput', 'imageTag', 'previewField']

  setPreviewUrl() {
    const file = event.target.files[0]
    const previewUrl = URL.createObjectURL(file)
    this.imageTagTarget.setAttribute('src', previewUrl)
  }

  resetPreviewUrl() {
    this.imageTagTarget.setAttribute('src', '');
  }

  resetFileInput() {
    this.fileInputTarget.value = "";
  }

  togglePreview() {
    this.previewFieldTargets.forEach((el) => {
      el.classList.toggle('hidden');
    })
  }

  closePreview() {
    this.resetPreviewUrl();
    this.resetFileInput();
    this.togglePreview();
  }
}
